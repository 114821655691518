import styled, { css } from "styled-components";
const Media = {
  Nav: "@media(max-width:1000px)",
  PhoneLarge: "@media(max-width:600px)",
  Laptop: "@media(max-width:1150px)",
};

export const HeaderContainer = styled.header`
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 99999;
  justify-content: space-around;
  padding-top: 1rem;
  min-height: 7.6rem;
  transition: 0.3s;
  border-radius: 0px 0px 20px 20px;
  ${({ isAnimation }) =>
    isAnimation &&
    css`
      transition: 0.3s;
      background-color: var(--bege);
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    `};
  ${Media.Nav} {
    background-color: var(--bege);
    align-items: center;
    min-height: 5.5rem;
  }
  .logo {
    margin-bottom: 1rem;
    img {
      display: block;
      width: 15rem;
      margin: auto;
      ${Media.Nav} {
        width: 10rem;
        margin: inherit;
      }
    }
  }

  .menu {
    display: none;
    ${Media.Nav} {
      display: block;
      position: absolute;
      right: 10%;
      width: 3rem;
      height: 3rem;
      color: var(--wine);
      opacity: 1;
      transition: all 0.5s linear 0.1s;
      ${({ isVisible }) =>
        isVisible &&
        css`
          opacity: 0;
          transition: all 0.5s linear 0.1s;
        `};
    }
  }

  .closeMenu {
    position: absolute;
    opacity: 0;
    z-index: -1;
    right: 10%;
    transition: all 0.1s linear;
    color: var(--wine);
    ${Media.Nav} {
      ${({ isVisible }) =>
        isVisible &&
        css`
          z-index: 0;
          opacity: 1;
        `}
    }
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 2rem;
    ${Media.Nav} {
      width: 100%;
      margin-left: 100%;
      padding: 0 10rem 100vh 2rem;
      position: absolute;
      top: 4rem;
      bottom: 34px;
      z-index: -2;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: fixed;
      min-height: 25rem;
      gap: 1.3rem;
      ${({ isVisible }) =>
        isVisible &&
        css`
          background-color: var(--bege);
          backdrop-filter: blur(15px);
          transition: margin-left 0.8s cubic-bezier(0.65, 0.05, 0.36, 1);
          opacity: 1;
          margin-left: 1px;
          left: -2.1rem;
        `}
    }
    .link {
      li,
      .option {
        font-size: 1.2rem;
        position: relative;
        z-index: 1;
        list-style-type: none;
        font-family: "Comfortaa";
        color: var(--wine);
      }

      .clicked {
        color: var(--orange);
        ${Media.Nav} {
        }
      }
    }
  }
  .mob {
    display: none !important;

    ${Media.Nav} {
      display: block !important;
    }
  }
  .contactContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    ${Media.Nav} {
      display: none;
    }
    .iconContact {
      width: 2rem;
      height: 2rem;
      color: var(--wine);
    }
  }
`;
